import { Dispatch, SetStateAction, useState } from 'react'
import {
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  VStack
} from '@chakra-ui/react'
import { NextRouter, useRouter } from 'next/router'
import { useFormContext, useWatch } from 'react-hook-form'
import { toast } from 'react-toastify'
import { resetPassword } from 'API/authenticate'
import { handleError } from 'API/error'
import PasswordField from 'components/PasswordField'
import { PASSWORD_PATTERN } from 'constants/common'
import routes from 'routes'
import { ECurrentStep, ELoginType } from '../../constant'

interface IResetPasswordProps {
  setCurrentStep: Dispatch<SetStateAction<ECurrentStep>>
  setLoginType: Dispatch<SetStateAction<ELoginType>>
}

const ResetPassword = (props: IResetPasswordProps) => {
  const { setCurrentStep, setLoginType } = props
  const { control, handleSubmit } = useFormContext()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const router: NextRouter = useRouter()
  const newPassword: string = useWatch({ control, name: 'newPassword' })
  const confirmPassword: string = useWatch({ control, name: 'newPassword' })
  const resetPasswordToken: string = String(router?.query?.resetPassword)

  async function onSubmit(): Promise<void> {
    try {
      setIsLoading(true)
      const result: boolean = await resetPassword({ newPassword, confirmPassword, resetPasswordToken })
      if (!result) {
        toast.error('Something wrong happened')
      } else {
        toast.success('Reset password successfully')
      }
      setIsLoading(false)
      router.push(routes.home.value)
      setCurrentStep(ECurrentStep.PERSONAL_INFORMATION)
      setLoginType(ELoginType.EMAIL)
    } catch (error) {
      handleError(
        error as Error,
        'components/pages/DetailPage/components/SideBar/components/LoginModal/components/ResetPassword/index.tsx',
        'onSubmit'
      )
    }
  }

  function handleResetPassword(): void {
    handleSubmit(onSubmit)()
  }

  return (
    <ModalContent>
      <ModalHeader
        fontSize="lg"
        color="gray.800"
        fontWeight={500}
        lineHeight={7}
        borderBottom="1px"
        borderColor="gray.200"
      >
        <Text>Reset Password</Text>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody fontWeight={500} paddingY={6} paddingX={{ base: 4, md: 6 }}>
        <VStack alignItems="flex-start">
          <Text color="gray.700" marginBottom={4}>
            Please provide your new password
          </Text>
          <PasswordField
            name="newPassword"
            label="New Password"
            pattern={{ value: PASSWORD_PATTERN, message: 'Please enter a valid password' }}
          />
          <VStack alignItems="flex-start" color="gray.500" fontSize="14px" fontWeight={400} spacing={0}>
            <Text as="li">Upper and lower case letters</Text>
            <Text as="li">At least 8 characters</Text>
            <Text as="li">Must contain digits</Text>
          </VStack>
        </VStack>
      </ModalBody>
      <ModalFooter paddingTop={4} paddingBottom={4} borderTop="1px" borderColor="gray.200" justifyContent="flex-end">
        <Button colorScheme="teal" lineHeight={6} onClick={handleResetPassword} isLoading={isLoading}>
          Reset Password
        </Button>
      </ModalFooter>
    </ModalContent>
  )
}

export default ResetPassword
