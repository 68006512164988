import { useState, useEffect } from 'react'
import { chakra, Modal, ModalOverlay } from '@chakra-ui/react'
import { EBreakPoint } from 'enums/theme'
import useBreakPoint from 'hooks/useBreakPoint'
import { useStores } from 'hooks/useStores'
import { observer } from 'mobx-react'
import { NextRouter, useRouter } from 'next/router'
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form'
import { toast } from 'react-toastify'
import { verifyEmail } from 'API/authenticate'
import { handleError } from 'API/error'
import { TEST_PHONE_NUMBER } from 'components/pages/DetailPage/constants'
import { RAW_US_PHONE_NUMBER_PATTERN } from 'constants/common'
import { IWebsiteLoginResponse } from 'interfaces/authenticate'
import { getRawUSPhoneNumber } from 'utils/common'
import CompleteScreen from './components/CompleteScreen'
import ForgotPassword from './components/ForgotPassword'
import PersonalInformationStep from './components/PersonalInformationStep'
import ResetPassword from './components/ResetPassword'
import SignUpStep from './components/SignUpStep'
import VerificationStep from './components/VerificationStep'
import { ILoginModalProps, ILoginForm, ELoginType, ECurrentStep } from './constant'

const LoginModal = (props: ILoginModalProps) => {
  const { isOpen, onClose, onOpen, handleAfterAuth, previousPhoneNumber, setApplicationValue } = props
  const { authStore } = useStores()
  const { isLogin, user } = authStore
  const isMobile: boolean = useBreakPoint(EBreakPoint.BASE, EBreakPoint.MD)
  const [currentLoginType, setCurrentLoginType] = useState<ELoginType>(ELoginType.PHONE_NUMBER)
  const [currentStep, setCurrentStep] = useState<ECurrentStep>(ECurrentStep.PERSONAL_INFORMATION)
  const methods: UseFormReturn = useForm<ILoginForm>()
  const { handleSubmit, setValue, reset } = methods
  const emailVerificationText =
    'Please check your email and follow the instruction to activate your account.\nIf you don’t receive the email, check your Spam'
  const router: NextRouter = useRouter()

  async function goToVerificationStep(data: ILoginForm): Promise<void> {
    const { phoneNumber = '', email = '' } = data

    try {
      const rawPhoneNumber: string = getRawUSPhoneNumber(phoneNumber)
      const isTestPhoneNumber: boolean = rawPhoneNumber === TEST_PHONE_NUMBER
      const isValidUSPhoneNumber: boolean = RAW_US_PHONE_NUMBER_PATTERN.test(rawPhoneNumber)
      if (currentLoginType === ELoginType.PHONE_NUMBER) {
        if (!isValidUSPhoneNumber) {
          toast.error(`The number ${phoneNumber} is not a valid phone number.`)
        } else {
          if (!isTestPhoneNumber) {
            await authStore.requestOtpToken(rawPhoneNumber)
            setCurrentStep(ECurrentStep.VERIFICATION)
          } else {
            const response: IWebsiteLoginResponse = await authStore.loginWithPhoneNumber(rawPhoneNumber)
            if (response?.userId) {
              if (!response?.isEmailVerified) {
                verifyEmail({
                  id: response?.userId
                })
                setCurrentStep(ECurrentStep.EMAIL_VERIFICATION)
                return
              }
              await authStore.setAccessTokenWithPhoneNumber(rawPhoneNumber)
              toast.success('Login successfully!')
              onClose()
            } else {
              setCurrentStep(ECurrentStep.SIGN_UP)
            }
          }
        }
      } else if (currentLoginType === ELoginType.EMAIL) {
        const websiteLoginResponse: IWebsiteLoginResponse = await authStore.loginWithEmail(email)
        if (websiteLoginResponse?.userId) {
          setCurrentStep(ECurrentStep.VERIFICATION)
        } else {
          setCurrentStep(ECurrentStep.SIGN_UP)
        }
      }
    } catch (error) {
      toast.error(`The number ${phoneNumber} is not a valid phone number.`)
      handleError(
        error as Error,
        'components/pages/DetailPage/components/SideBar/components/LoginModal/index.tsx',
        'goToVerificationStep()'
      )
    }
  }

  function handleCloseModal(): void {
    onClose()
    setCurrentStep(ECurrentStep.PERSONAL_INFORMATION)
  }

  useEffect(() => {
    if (currentStep === ECurrentStep.COMPLETE || currentStep === ECurrentStep.EMAIL_VERIFICATION) {
      const timer: NodeJS.Timeout = setTimeout(() => {
        if (currentStep === ECurrentStep.EMAIL_VERIFICATION) {
          return
        }
        handleCloseModal()
      }, 3000)
      typeof handleAfterAuth === 'function' && handleAfterAuth()
      return () => clearTimeout(timer)
    }
    return () => {}
  }, [currentStep, isLogin])

  useEffect(() => {
    if (previousPhoneNumber) {
      setValue('phoneNumber', previousPhoneNumber)
    }
  }, [previousPhoneNumber])

  useEffect(() => {
    if (isLogin) {
      reset()
      typeof setApplicationValue === 'function' && setApplicationValue('phoneNumber', '')
    }
  }, [isLogin])

  useEffect(() => {
    if (router?.query?.resetPassword) {
      onOpen()
      setCurrentStep(ECurrentStep.RESET_PASSWORD)
    }
  }, [])

  useEffect(() => {
    // *INFO: handle sign up after login with google account
    if (router?.query?.isSignUp) {
      reset({ ...user })
      onOpen()
      setCurrentStep(ECurrentStep.SIGN_UP)
      setCurrentLoginType(ELoginType.EMAIL)
    }
  }, [])

  // * INFO: use handleSubmit instead of passing goToVerificationStep as a props to verify valid phoneNumber or email
  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal} size={isMobile ? 'full' : 'md'} isCentered>
      <FormProvider {...methods}>
        <chakra.form width="full" onSubmit={handleSubmit(goToVerificationStep)}>
          <ModalOverlay />
          {currentStep === ECurrentStep.PERSONAL_INFORMATION && (
            <PersonalInformationStep loginType={currentLoginType} setLoginType={setCurrentLoginType} />
          )}
          {currentStep === ECurrentStep.VERIFICATION && (
            <VerificationStep loginType={currentLoginType} setCurrentStep={setCurrentStep} onClose={handleCloseModal} />
          )}
          {currentStep === ECurrentStep.SIGN_UP && (
            <SignUpStep loginType={currentLoginType} setCurrentStep={setCurrentStep} />
          )}
          {currentStep === ECurrentStep.FORGOT_PASSWORD && (
            <ForgotPassword setCurrentStep={setCurrentStep} setLoginType={setCurrentLoginType} />
          )}
          {currentStep === ECurrentStep.RESET_PASSWORD && (
            <ResetPassword setCurrentStep={setCurrentStep} setLoginType={setCurrentLoginType} />
          )}
          {currentStep === ECurrentStep.COMPLETE && <CompleteScreen />}
          {currentStep === ECurrentStep.EMAIL_VERIFICATION && <CompleteScreen bodyText={emailVerificationText} />}
        </chakra.form>
      </FormProvider>
    </Modal>
  )
}

export default observer(LoginModal)
