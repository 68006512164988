import { NUMBER_WITH_COMMA_PATTERN } from 'constants/common'
import { roundNumberToFixedDigits } from 'utils/common'
import {
  STUDIO_CLEANING_FEE,
  STUDIO_WIFI_FEE,
  CL_CLEANING_FEE,
  CL_WIFI_FEE,
  HR_WIFI_FEE,
  CL_MARGIN_RATIO,
  STUDIO_MARGIN_RATIO,
  HR_CLEANING_FEE
} from './constants'

export function formatPrice(number: number): string {
  return roundNumberToFixedDigits(number, 0).replace(NUMBER_WITH_COMMA_PATTERN, ',')
}

interface IPricingTableData {
  clAverageRent: string
  studioAverageRent: string
  hrTotalCost: string
  clTotalCost: string
  studioTotalCost: string
}

export function getPricingTableData(hrAverageRent: number, hrServiceFee: number, hasWifi: boolean): IPricingTableData {
  const clAverageRentNumberData: number = hrAverageRent * CL_MARGIN_RATIO
  const studioAverageRentNumberData: number = hrAverageRent * STUDIO_MARGIN_RATIO

  const clAverageRent: string = formatPrice(hrAverageRent * CL_MARGIN_RATIO)
  const studioAverageRent: string = formatPrice(hrAverageRent * STUDIO_MARGIN_RATIO)

  const hrTotalCost: string = hasWifi
    ? formatPrice(hrAverageRent + hrServiceFee + HR_CLEANING_FEE + HR_WIFI_FEE)
    : formatPrice(hrAverageRent + hrServiceFee + HR_CLEANING_FEE)

  const clTotalCost: string = hasWifi
    ? formatPrice(clAverageRentNumberData + CL_CLEANING_FEE + CL_WIFI_FEE)
    : formatPrice(clAverageRentNumberData + CL_CLEANING_FEE)

  const studioTotalCost: string = hasWifi
    ? formatPrice(studioAverageRentNumberData + STUDIO_CLEANING_FEE + STUDIO_WIFI_FEE)
    : formatPrice(studioAverageRentNumberData + STUDIO_CLEANING_FEE)

  return {
    clAverageRent,
    studioAverageRent,
    hrTotalCost,
    clTotalCost,
    studioTotalCost
  }
}
