import { chakra, Text, MenuItem as CkMenuItem } from '@chakra-ui/react'
import { backgroundItemHeaderHover } from '../Navbar/constants'

export const MenuItem = chakra(CkMenuItem, {
  baseStyle: () => ({
    maxHeight: '32px',
    _focus: { background: backgroundItemHeaderHover },
    _active: { background: backgroundItemHeaderHover },
    _hover: { background: backgroundItemHeaderHover },
    paddingY: '6px',
    paddingX: '12px'
  })
})

export const MenuItemText = chakra(Text, {
  baseStyle: () => ({
    fontSize: 'sm',
    fontWeight: '600',
    lineHeight: '20px',
    color: 'teal.50',
    width: '100%',
    height: '100%'
  })
})
