import { useEffect, useState } from 'react'
import { TableContainer } from '@chakra-ui/react'
import { EBreakPoint } from 'enums/theme'
import useBreakPoint from 'hooks/useBreakPoint'
import { isMobile as isMobileDetect, isTablet as isTabletDetect } from 'react-device-detect'
import { IMetroWithRelations } from 'interfaces/metro'

import DesktopPricingTable from './components/DesktopPricingTable'
import ResponsivePricingTable from './components/ResponsivePricingTable'

interface IPricingTable {
  metroWithPricing: IMetroWithRelations
}

const PricingTable = (props: IPricingTable) => {
  const { metroWithPricing } = props
  // *INFO: using for detect with real device
  const isMobileTabletDevice: boolean = isMobileDetect || isTabletDetect
  const isMobileBreakPoint: boolean = useBreakPoint(EBreakPoint.BASE, EBreakPoint.MD)
  const [isResponsive, setIsResponsive] = useState<boolean>(false)
  const [isMounted, setIsMounted] = useState<boolean>(false)

  // *INFO: using check mounted state of component to fix error overwrite style Chakra
  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true)
    }
  }, [])

  // *INFO: using responsive state when change screen size
  useEffect(() => {
    setIsResponsive(isMobileTabletDevice || isMobileBreakPoint)
  }, [isMobileBreakPoint])

  return (
    <TableContainer
      marginTop={{ base: '24px !important', md: '52px !important' }}
      width={{ base: 'full', lg: 'unset' }}
    >
      {isResponsive && isMounted ? (
        <ResponsivePricingTable metroWithPricing={metroWithPricing} />
      ) : (
        <DesktopPricingTable metroWithPricing={metroWithPricing} />
      )}
    </TableContainer>
  )
}

export default PricingTable
