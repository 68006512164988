import { Fragment, ReactNode } from 'react'

export interface ITrustCredibilityFAQsCard {
  title: ReactNode
  description: string
  image: string
}

export const trustCredibilityFAQsItems: ITrustCredibilityFAQsCard[] = [
  {
    title: (
      <Fragment>
        <b>AMENITIES</b>
        &nbsp;YOU’LL ACTUALLY LOVE
      </Fragment>
    ),
    description:
      'Tech-friendly and stylish homes, furnished common areas, really fast WiFi, great community events, 24/7 support, maid and garden service.',
    image: '/assets/images/amenities.png'
  },
  {
    title: (
      <Fragment>
        SERIOUSLY&nbsp;
        <b>AFFORDABLE</b>
        &nbsp;RENT
      </Fragment>
    ),
    description:
      "Low rent to help you chip away at debt and build your savings faster. Flexible leases for life's unexpected changes.",
    image: '/assets/images/affordable_rent.png'
  },
  {
    title: (
      <Fragment>
        NO MORE HIT-OR-MISS&nbsp;
        <b>ROOMMATES</b>
      </Fragment>
    ),
    description:
      'Fine-tuned roommate matching to ensure your living preferences and vibe are a fit, HomeRoom sponsored social events to connect with other like-minded members nearby.',
    image: '/assets/images/roommates.png'
  },
  {
    title: (
      <Fragment>
        AN&nbsp;
        <b>APP</b>
        &nbsp;FOR EVERYTHING
      </Fragment>
    ),
    description:
      'Pay rent, lock your bedroom door, RSVP to events, make service requests and so much more all through our renter app.',
    image: '/assets/images/homeroom_app.png'
  }
]
