import React, { ClipboardEvent } from 'react'
import { Input } from '@chakra-ui/react'
import { useFormContext, Controller } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { PHONE_NUMBER_PATTERN } from 'constants/common'
import { formatUSPhoneNumber } from 'utils/common'

interface ICustomFormPhoneInputProps {
  isAutoFocus?: boolean
}

const CustomFormPhoneInput = (props: ICustomFormPhoneInputProps) => {
  const { isAutoFocus } = props
  const { control, setValue } = useFormContext()

  function onPastePhoneInput(event: ClipboardEvent<HTMLInputElement>): void {
    const clipboardData: DataTransfer = event.clipboardData
    const pastedPhoneNumber: string = clipboardData.getData('Text').replace(/\n/g, '')
    if (pastedPhoneNumber.startsWith('+1')) {
      const phoneNumber: string = formatUSPhoneNumber(pastedPhoneNumber)
      setValue('phoneNumber', phoneNumber)
    }
  }

  return (
    <Controller
      name={'phoneNumber'}
      control={control}
      rules={{
        required: 'Phone number is required',
        pattern: {
          value: PHONE_NUMBER_PATTERN,
          message: 'Invalid Phone Number'
        }
      }}
      render={({ field: { onChange, value } }) => (
        <Input
          autoFocus={isAutoFocus}
          height={{ base: 12, lg: 10 }}
          as={NumberFormat}
          value={value}
          onChange={onChange}
          format="(###) ###-####"
          placeholder={'(000) 000 - 0000'}
          marginTop="0 !important"
          onPaste={onPastePhoneInput}
        />
      )}
    />
  )
}

export default CustomFormPhoneInput
