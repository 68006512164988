import { chakra, Table, Td, Th } from '@chakra-ui/react'

export const PricingTable = chakra(Table, {
  baseStyle: () => ({
    tableLayout: 'fixed',
    variant: 'simple',
    size: 'sm',
    overflowX: 'hidden',
    whiteSpace: 'normal'
  })
})

export const PricingTh = chakra(Th, {
  baseStyle: () => ({
    padding: 2,
    fontSize: 'xs',
    fontWeight: 600,
    lineHeight: 4,
    textAlign: 'center',
    textTransform: 'capitalize'
  })
})

export const PricingTd = chakra(Td, {
  baseStyle: () => ({
    padding: '12px 0px 12px 8px',
    color: 'gray.700',
    fontSize: 'xs',
    fontWeight: 500,
    lineHeight: 4,
    textAlign: 'left'
  })
})

export const PricingBoldTd = chakra(Td, {
  baseStyle: () => ({
    paddingX: 0,
    paddingY: 3,
    color: 'gray.700',
    fontSize: 'sm',
    fontWeight: 600,
    lineHeight: 5,
    textAlign: 'center',
    background: 'rgba(230, 255, 250, 0.3)'
  })
})

export const PricingNormalTd = chakra(Td, {
  baseStyle: () => ({
    paddingX: 0,
    paddingY: 3,
    color: 'gray.700',
    fontSize: 'sm',
    fontWeight: 400,
    lineHeight: 5,
    textAlign: 'center'
  })
})
