import React from 'react'
import { Box as CkBox, VStack, Text, Center } from '@chakra-ui/react'
import { IMetroWithRelations, IPricingTableSettings } from 'interfaces/metro'
import { getValidArray } from 'utils/common'
import PricingTable from './components/PricingTable'

interface IPricingTableSection {
  metroWithPricing?: IMetroWithRelations
  pricingSettings?: IPricingTableSettings
}

const PricingTableSection = (props: IPricingTableSection) => {
  const { metroWithPricing = {} as IMetroWithRelations, pricingSettings = {} as IPricingTableSettings } = props
  const notes: string[] = pricingSettings?.note?.split('<br>')
  //*INFO: replace <cite> to <span> with style follow design
  const title: string = pricingSettings?.title
    ?.replace(/<cite>(.*?)<\/cite>/g, '<span style="color: teal;">$1</span>')
    ?.replace(/<\/?cite>/g, '')

  return (
    <CkBox background="white" paddingX={{ base: 4, md: 8 }} paddingY={{ base: 10, md: 20 }}>
      <VStack width="full" spacing={{ base: 6, md: 14, lg: 20 }}>
        <VStack spacing={{ base: 4, lg: 6 }} paddingX={{ md: '62px' }}>
          <Text
            fontSize={{ base: 'xl', md: '3xl', lg: '4xl' }}
            fontWeight="bold"
            lineHeight={{ base: 7, md: '44px' }}
            align="center"
            maxWidth={{ base: '85%', md: '100%' }}
            dangerouslySetInnerHTML={{ __html: title }}
          ></Text>
          <Text
            as="p"
            fontSize={{ base: 'md', lg: 'lg' }}
            color="gray.800"
            align="center"
            lineHeight={{ base: 6, md: 7 }}
            marginTop={{ base: 4, md: 6 }}
            width="full"
            maxWidth={{ base: '85%', md: '568px' }}
            dangerouslySetInnerHTML={{ __html: pricingSettings?.description ?? '' }}
          ></Text>
        </VStack>
        <PricingTable metroWithPricing={metroWithPricing} />
        <Center marginTop={{ base: 6, md: '40px !important' }}>
          <VStack spacing={2} maxWidth="506px">
            {getValidArray(notes).map((note: string, index: number) => (
              <Text
                key={index}
                textAlign="center"
                fontSize="sm"
                color="gray.600"
                lineHeight={5}
                dangerouslySetInnerHTML={{ __html: note }}
              ></Text>
            ))}
          </VStack>
        </Center>
      </VStack>
    </CkBox>
  )
}

export default PricingTableSection
