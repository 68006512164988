import React from 'react'
import { Box as CkBox, VStack, Grid, Text } from '@chakra-ui/react'
import { EBreakPoint } from 'enums/theme'
import useBreakPoint from 'hooks/useBreakPoint'
import { TrustCredibilityFAQsCard } from './components/TrustCredibilityFAQCard'
import { trustCredibilityFAQsItems } from './constants'

const TrustCredibilityFAQsSection = () => {
  const isNotMobile: boolean = useBreakPoint(EBreakPoint.MD)

  return (
    <CkBox background="#f3eada">
      <VStack
        width="full"
        paddingX={{ base: 4, md: '93px', lg: '150px' }}
        paddingY={{ base: 10, lg: 20 }}
        spacing={{ base: 6, md: 14, lg: 20 }}
      >
        <VStack spacing={{ base: 4, lg: 6 }} paddingX={{ md: '62px' }}>
          <Text
            fontSize={{ base: 'xl', md: '3xl', lg: '4xl' }}
            fontWeight="bold"
            lineHeight={{ base: 7, md: 10 }}
            align="center"
          >
            <Text as="span" color="teal.500">
              More than
            </Text>
            &nbsp;just a house full of random roommates
          </Text>
          <Text
            fontSize={{ base: 'md', lg: 'lg' }}
            color="gray.800"
            align="center"
            lineHeight={{ base: 6, lg: 7 }}
            marginX={{ lg: '37.5px !important' }}
            width="full"
            maxWidth={{ base: '85%', md: '90%', lg: '100%' }}
          >
            We have elevated the home-sharing concept to a new level.&nbsp;
            {isNotMobile && <br />}
            Sure, we match roommates to their ideal houses and roommates,&nbsp;
            <Text as="span" fontWeight="bold">
              but there&apos;s so much more.
            </Text>
          </Text>
        </VStack>

        <Grid templateColumns={{ md: 'repeat(2, 1fr)', lg: 'repeat(2, 1fr)' }} columnGap={8} rowGap={10}>
          {trustCredibilityFAQsItems.map((item, index) => (
            <TrustCredibilityFAQsCard
              key={index}
              title={item.title}
              description={item.description}
              image={item.image}
            />
          ))}
        </Grid>
      </VStack>
    </CkBox>
  )
}

export default TrustCredibilityFAQsSection
