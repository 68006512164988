import { Table, Thead, Tbody } from '@chakra-ui/react'
import EFeatureFlags from 'enums/featureFlags'
import useFeatureFlagValue from 'hooks/useFeatureFlagValue'
import { IMetroWithRelations } from 'interfaces/metro'
import {
  PricingTableTh,
  PricingTableNormalTd,
  PricingTableHighlightTd,
  PricingTableTr
} from '../../../../pricingTableFooter.styles'
import {
  CL_CLEANING_FEE,
  CL_WIFI_FEE,
  HR_CLEANING_FEE,
  HR_WIFI_FEE,
  STUDIO_CLEANING_FEE,
  STUDIO_WIFI_FEE
} from '../../constants'
import { getPricingTableData, formatPrice } from '../../utils'
import { EPricingTableThContent, EPricingTableNormalTdContent } from './constants'
interface IDesktopPricingTableProps {
  metroWithPricing: IMetroWithRelations
}

const DesktopPricingTable = (props: IDesktopPricingTableProps) => {
  const { metroWithPricing } = props
  const hrAverageRent: number = metroWithPricing?.metroPricing?.averageRent ?? 0
  const hrServiceFee: number = metroWithPricing?.metroPricing?.serviceFee ?? 0
  const { isEnabled: hasWifi = false } = useFeatureFlagValue(EFeatureFlags.WEBSITE_PRICING_TABLE_WIFI)
  const { clAverageRent, studioAverageRent, hrTotalCost, clTotalCost, studioTotalCost } = getPricingTableData(
    hrAverageRent,
    hrServiceFee,
    hasWifi
  )

  return (
    <Table variant="simple" size="sm" overflowX="hidden" whiteSpace="normal">
      <Thead>
        <PricingTableTr height={{ base: 16, md: '60px' }}>
          <PricingTableTh textAlign="left" display="table-cell">
            {metroWithPricing?.name}
          </PricingTableTh>
          <PricingTableTh backgroundColor="teal.500" color="white" borderRadius="8px 8px 0px 0px">
            {EPricingTableThContent.HOMEROOM}
          </PricingTableTh>
          <PricingTableTh fontWeight={400} fontSize={{ base: 'md', md: 'lg' }}>
            {EPricingTableThContent.CRAIGSLIST_ROOM}
          </PricingTableTh>
          <PricingTableTh fontWeight={400} fontSize={{ base: 'md', md: 'lg' }}>
            {EPricingTableThContent.TRADITIONAL_STUDIO}
          </PricingTableTh>
        </PricingTableTr>
      </Thead>
      <Tbody>
        <PricingTableTr>
          <PricingTableNormalTd textAlign="left" fontWeight={600} display="table-cell">
            {EPricingTableNormalTdContent.AVERAGE_RENT}
          </PricingTableNormalTd>
          <PricingTableHighlightTd>${formatPrice(hrAverageRent)}</PricingTableHighlightTd>
          <PricingTableNormalTd>${clAverageRent}</PricingTableNormalTd>
          <PricingTableNormalTd>${studioAverageRent}</PricingTableNormalTd>
        </PricingTableTr>
        <PricingTableTr>
          <PricingTableNormalTd textAlign="left" display="table-cell">
            {EPricingTableNormalTdContent.CLEANING}
          </PricingTableNormalTd>
          <PricingTableHighlightTd>${HR_CLEANING_FEE}</PricingTableHighlightTd>
          <PricingTableNormalTd>${CL_CLEANING_FEE}</PricingTableNormalTd>
          <PricingTableNormalTd>${STUDIO_CLEANING_FEE}</PricingTableNormalTd>
        </PricingTableTr>
        {hasWifi && (
          <PricingTableTr>
            <PricingTableNormalTd textAlign="left" display="table-cell">
              {EPricingTableNormalTdContent.WIFI}
            </PricingTableNormalTd>
            <PricingTableHighlightTd>${HR_WIFI_FEE}</PricingTableHighlightTd>
            <PricingTableNormalTd>${CL_WIFI_FEE}</PricingTableNormalTd>
            <PricingTableNormalTd>${STUDIO_WIFI_FEE}</PricingTableNormalTd>
          </PricingTableTr>
        )}
        <PricingTableTr>
          <PricingTableNormalTd textAlign="left" display="table-cell">
            {EPricingTableNormalTdContent.SMART_TV}
          </PricingTableNormalTd>
          <PricingTableHighlightTd>Included</PricingTableHighlightTd>
          <PricingTableNormalTd>-</PricingTableNormalTd>
          <PricingTableNormalTd>-</PricingTableNormalTd>
        </PricingTableTr>
        <PricingTableTr>
          <PricingTableNormalTd textAlign="left" display="table-cell">
            {EPricingTableNormalTdContent.COMMON_AREA_FURNISHING}
          </PricingTableNormalTd>
          <PricingTableHighlightTd>Included</PricingTableHighlightTd>
          <PricingTableNormalTd>-</PricingTableNormalTd>
          <PricingTableNormalTd>-</PricingTableNormalTd>
        </PricingTableTr>
        <PricingTableTr>
          <PricingTableNormalTd textAlign="left" display="table-cell">
            {EPricingTableNormalTdContent.KITCHEN_ESSENTIALS}
          </PricingTableNormalTd>
          <PricingTableHighlightTd>Included</PricingTableHighlightTd>
          <PricingTableNormalTd>-</PricingTableNormalTd>
          <PricingTableNormalTd>-</PricingTableNormalTd>
        </PricingTableTr>
        <PricingTableTr>
          <PricingTableNormalTd textAlign="left" display="table-cell">
            {EPricingTableNormalTdContent.SUPPORT_AND_MAINTENANCE}
          </PricingTableNormalTd>
          <PricingTableHighlightTd>Included</PricingTableHighlightTd>
          <PricingTableNormalTd>-</PricingTableNormalTd>
          <PricingTableNormalTd>-</PricingTableNormalTd>
        </PricingTableTr>
        <PricingTableTr>
          <PricingTableNormalTd textAlign="left" display="table-cell">
            {EPricingTableNormalTdContent.SERVICES_FEE}
          </PricingTableNormalTd>
          <PricingTableHighlightTd>${formatPrice(hrServiceFee)}</PricingTableHighlightTd>
          <PricingTableNormalTd>-</PricingTableNormalTd>
          <PricingTableNormalTd>-</PricingTableNormalTd>
        </PricingTableTr>
        <PricingTableTr height={{ base: '10 !important', md: '60px' }}>
          <PricingTableNormalTd
            textAlign="left"
            fontWeight={600}
            display="table-cell"
            paddingY={{ base: 4, md: 'auto' }}
          >
            {EPricingTableNormalTdContent.TOTAL_COST}
          </PricingTableNormalTd>
          <PricingTableHighlightTd
            backgroundColor="teal.500"
            color="white"
            borderRadius="0px 0px 8px 8px"
            height="inherit"
          >
            ${hrTotalCost}
          </PricingTableHighlightTd>
          <PricingTableNormalTd fontWeight={600} height="inherit">
            ${clTotalCost}
          </PricingTableNormalTd>
          <PricingTableNormalTd fontWeight={600} height="inherit">
            ${studioTotalCost}
          </PricingTableNormalTd>
        </PricingTableTr>
      </Tbody>
    </Table>
  )
}

export default DesktopPricingTable
