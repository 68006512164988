import { Dispatch, SetStateAction, useState } from 'react'
import {
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  VStack
} from '@chakra-ui/react'
import { useFormContext, useWatch } from 'react-hook-form'
import { forgotPassword } from 'API/authenticate'
import { PLATFORM } from 'API/constants'
import { handleError } from 'API/error'
import FormInput from 'components/FormInput'
import { ECurrentStep, ELoginType } from '../../constant'

interface IForgotPasswordProps {
  setCurrentStep: Dispatch<SetStateAction<ECurrentStep>>
  setLoginType: Dispatch<SetStateAction<ELoginType>>
}

const ForgotPassword = (props: IForgotPasswordProps) => {
  const { setCurrentStep, setLoginType } = props
  const { control } = useFormContext()
  const [isSent, setIsSent] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const userEmail: string = useWatch({ control, name: 'email' }) || ''

  function backToLoginWithPhoneNumber(): void {
    setCurrentStep(ECurrentStep.PERSONAL_INFORMATION)
    setLoginType(ELoginType.PHONE_NUMBER)
  }

  async function onSubmit(): Promise<void> {
    try {
      setIsLoading(true)
      await forgotPassword({ email: userEmail, platform: PLATFORM.WEBSITE })
      setIsLoading(false)
      setIsSent(true)
    } catch (error) {
      handleError(
        error as Error,
        'components/pages/DetailPage/components/SideBar/components/LoginModal/components/ForgotPassword/index.tsx',
        'onSubmit'
      )
    }
  }

  return (
    <ModalContent>
      <ModalHeader
        fontSize="lg"
        color="gray.800"
        fontWeight={500}
        lineHeight={7}
        borderBottom="1px"
        borderColor="gray.200"
      >
        <Text>Forgot Password?</Text>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody fontWeight={500} paddingY={6} paddingX={{ base: 4, md: 6 }}>
        {isSent ? (
          <VStack height="200px" alignItems="flex-start" spacing={6}>
            <Text>Please check your email and follow the instruction to reset your password</Text>

            <Text>
              If you don’t receive the email, check your Spam or{' '}
              <Text color="teal.500" cursor="pointer" onClick={() => setIsSent(false)}>
                ask again to reset your password
              </Text>
            </Text>
          </VStack>
        ) : (
          <VStack alignItems="flex-start" spacing={6}>
            <Text>
              Please enter the email address associated with your account, we will send you a link to reset password
            </Text>
            <FormInput name="email" label="Your Email Address" placeholder="Enter Your Email" />
            <Text as="u" color="blue.500" fontWeight={400} cursor="pointer" onClick={backToLoginWithPhoneNumber}>
              Or login with phone number
            </Text>
          </VStack>
        )}
      </ModalBody>
      {!isSent && (
        <ModalFooter
          paddingTop={4}
          paddingBottom={4}
          borderTop="1px"
          borderColor="gray.200"
          justifyContent="space-between"
        >
          <Button
            colorScheme="teal"
            variant="link"
            lineHeight={6}
            fontWeight={500}
            paddingX="0"
            _hover={{ background: 'none' }}
            _focus={{ border: 'none' }}
            onClick={() => setCurrentStep(ECurrentStep.VERIFICATION)}
          >
            Back
          </Button>
          <Button colorScheme="teal" lineHeight={6} onClick={onSubmit} isLoading={isLoading}>
            Send
          </Button>
        </ModalFooter>
      )}
    </ModalContent>
  )
}

export default ForgotPassword
