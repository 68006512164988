export interface IRenterFAQ {
  title: string
  questions: IQuestion[]
}

export interface IQuestion {
  question: string
  ideas?: string[]
  answer: string
  haveLink?: boolean
  secondAnswer?: string
}

export const renterFAQs: IRenterFAQ[] = [
  {
    title: 'Application Process & Requirements',
    questions: [
      {
        question: 'What do you require to become a HomeRoom member?',
        answer: 'There are six main steps in the application process which include: ',
        ideas: [
          `Step 1: View our homes: Discover your perfect room here.`,
          'Step 2: Apply: We will conduct comprehensive background and credit checks.',
          `Step 3: Request a tour: We'll coordinate a roommate call, allowing you to arrange a tour and ensure compatibility.`,
          'Step 4: Explore the space: Tour the house and meet your potential roommates.',
          'Step 5: Make it official: Digitally review and sign your agreement.',
          'Step 6: Welcome Home: Obtain your digital access key for the residence and the community.'
        ],
        haveLink: true
      },
      {
        question: 'What do you look for in applicants?',
        answer:
          'We are committed to welcoming inclusive residents from all backgrounds who are excited to live with one another and build a household and a community together. You’ll need to meet the following requirements to lease with HomeRoom:',
        ideas: [
          'Income: Applicants must demonstrate a gross monthly income equal to or exceeding two times the monthly rent.',
          'Credit Score: Applicants are required to have a positive credit history, characterized by consistent payments and satisfactory debt-to-income ratios.',
          'Leasing History: Applicants should have no recent evictions on their leasing history.',
          "Background Report: All applicants must meet our community's standards in accordance with Fair Housing Guidelines and local regulations."
        ]
      },
      {
        question: 'What does the application process consist of?',
        answer:
          'You can complete the entire application process online. To apply, please visit app.livehomeroom.com/apply and submit the following information:',
        ideas: [
          'Your name and address',
          'A government-issued form of identification',
          'Social security number',
          'Self-reported monthly income',
          'Verification of your income (e.g., pay stub)',
          'Application fee of $35'
        ]
      },
      {
        question: 'What if I need a cosigner? Who qualifies as a cosigner?',
        answer:
          'Cosigners must have a U.S. based address and a credit score of 650 or higher as well as  income that is three times the monthly rent.'
      },
      {
        question: 'What credit score do I need to qualify?',
        answer: 'Credit score requirements vary by state.'
      }
    ]
  },
  {
    title: 'Roommates / Meet & Greet',
    questions: [
      {
        question: 'Do I get to meet my roommates?',
        answer: `After your rental application has been approved, we will coordinate a roommate call with current residents of a home you are interested in. From there, you can coordinate a time to meet, set up time to video chat or ask them to take you on a video tour of the home. If, for whatever reason, it's not a mutual fit, HomeRoom will welcome you to chat with other roommates until you find your perfect home.`
      },
      {
        question: 'What are the house rules for each HomeRoom?',
        answer:
          'Yes, each house sets its own rules. However, to keep our homes happy we have outlined our Homes Policies here.',
        haveLink: true
      },
      {
        question: 'Will I have privacy in the home?',
        answer: `While residents share the entire home, each resident has their own private bedroom within the home.`
      },
      {
        question: 'How do we choose our roommates?',
        answer: `At HomeRoom, we place community at the core of our mission, and we firmly believe that strong communities begin within the home itself. This is why we meticulously crafted our roommate Meet & Greet process to empower our residents to thoroughly assess, engage in conversations, and acquaint themselves with potential roommates before making the decision to welcome them into their living space.`
      },
      {
        question: 'Can my spouse  live with me?',
        answer:
          'All rooms are single occupancy only. For visits, please review our visitor’s Policy here: Community Policies, Rules & Terms.',
        haveLink: true
      },
      {
        question: 'What are my responsibilities as a roommate? ',
        answer: 'You have a few responsibilities as a roommate and a few little maintenance tasks.',
        ideas: [
          'Weekly responsibilities like doing your dishes and cleaning after yourself, communicating with your roommates when bringing a visitor, and abiding by your house rules.',
          'Attending a roommate meeting at least every 3 months and everyday maintenance tasks.',
          'Examples of other maintenance requests that fall under resident responsibility include:',
          'Replacing reachable light bulbs',
          'Replace smart lock and fire alarm batteries',
          'Plumbing issues like clogged drains that arise due to resident use',
          'Testing and troubleshooting blown fuses',
          'Bathroom fixtures like disposable bathroom items, etc.',
          'AC Filter changing',
          'Internet troubleshooting',
          'Small pest issues',
          'Purchase household items and batteries (detergent, soap, and cleaning tools will be roommates’ responsibility)'
        ]
      }
    ]
  },
  {
    title: 'Leasing',
    questions: [
      {
        question: 'Are pets allowed?',
        answer:
          'We don’t allow pets in our homes. Should you require approval for an ESA, send all documentation via email (membersupport@livehomeroom).'
      },
      {
        question: 'How long am I able to rent for?',
        answer:
          'Residents can choose lease terms between 3 and 24 months. Residents can also move freely to other homes in different cities without breaking their lease.'
      },
      {
        question: 'Do I get to tour the house?',
        answer:
          'We offer virtual tours along with personalized insights on the home and neighborhood for all of our properties. We also offer in-person tours at select properties.'
      },
      {
        question: 'Are residents informed when a new roommate signs a lease in their home?',
        answer: 'Yes! We’ll send out an email to everyone prior to move in.'
      },
      {
        question: 'Is smoking allowed in the home?',
        answer: 'Smoking and illegal drugs of any kind are not allowed in the home or near the premises.'
      },
      {
        question: 'Is renter’s insurance required?',
        answer: 'Renter’s insurance is a must. Failure to upload your policy number will result in a $50 monthly fee.'
      },
      {
        question: 'What is the price of the home?',
        answer:
          'The price of the home or your portion of the rent will be listed on the property listings page at our 12-month lease rate. We offer lease lengths as little as 3 months and as long as 24 months. Pricing can be subject to change based on the lease term.'
      }
    ]
  },
  {
    title: 'Payments',
    questions: [
      {
        question: 'Do you require a security deposit?',
        answer: `When you sign your new lease, depending on the market, we collect a security deposit equal to one month's rent or an approved security deposit alternative. We also collect the first month’s rent and service fees.`
      },
      {
        question: 'How soon after I move out will I receive my security deposit?',
        answer: `Security deposits will be returned in accordance with applicable law.`
      },
      {
        question: 'When is my payment due?',
        answer: `Payment is due on the first of every month.`
      },
      {
        question: 'How can I access my Resident Portal account',
        answer: `Your Resident Portal is available through the pay section in the HomeRoom app. (Pay section)`,
        haveLink: true
      }
    ]
  },
  {
    title: 'Utilities & Fees',
    questions: [
      {
        question: 'What are the move-in costs?',
        answer: `When signing your new lease, you will be required to provide a security deposit equivalent to one month's rent or an approved alternative security deposit. In addition, the initial payment will include the first month's rent, along with service charges such as a utilities pre-payment of $140, a membership fee of $19, and a move-in fee of $300.`
      },
      {
        question: 'Does HomeRoom offer parking and is there a fee?',
        answer: `Parking is available for many of our homes. This is based on an individual property basis so please check the listing. An additional parking fee also varies by property depending on space limitations.`
      },
      {
        question: 'What does HomeRoom provide as a service?',
        answer: `We’re committed to providing residents in the HomeRoom community with an outstanding living experience. We charge a monthly membership fee of $19* which includes the below: `,
        ideas: [
          'Furnished common areas',
          'Smart TVs',
          'Kitchen essentials',
          'Roommate matching',
          'Internet',
          'Mobile App Access',
          'Security Deposit alternatives & help building credit'
        ],
        secondAnswer:
          'This does not include cleaning fee or utilities. Utilities based on actual usage, split evenly between all roommates.'
      },
      {
        question: 'Are utilities included in the advertised price?',
        answer: `No, but we make paying for utilities easy for each resident as part of our commitment to streamlining your rental experience. Utilities are based on actual usage and split between roommates.`
      }
    ]
  }
]
