import React from 'react'
import { ModalBody, ModalCloseButton, ModalContent, ModalHeader, Text, VStack, Image } from '@chakra-ui/react'

export interface ICompleteScreenProps {
  bodyText?: string
}

const CompleteScreen = (props: ICompleteScreenProps) => {
  const { bodyText = 'Your account has been created successfully' } = props
  const welcomeImageUrl: string = '/assets/images/welcome.png'
  return (
    <ModalContent>
      <ModalHeader
        fontSize="lg"
        color="gray.800"
        fontWeight={500}
        lineHeight={7}
        borderBottom="1px"
        borderColor="gray.200"
      >
        <Text>Welcome to HomeRoom</Text>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody lineHeight={7} color="gray.700" fontWeight={500} paddingY={14} paddingX={12}>
        <VStack spacing={4}>
          <Image src={welcomeImageUrl} alt="welcome" width={16} height={16} />
          <Text fontSize="xl">CONGRATS!</Text>
          <Text textAlign="center">{bodyText}</Text>
        </VStack>
      </ModalBody>
    </ModalContent>
  )
}

export default CompleteScreen
