export const STUDIO_CLEANING_FEE: number = 250
export const STUDIO_WIFI_FEE: number = 120

export const CL_CLEANING_FEE: number = 125
export const CL_WIFI_FEE: number = 60

export const HR_CLEANING_FEE: number = 75
export const HR_WIFI_FEE: number = 35

export const CL_MARGIN_RATIO: number = 1.1
export const STUDIO_MARGIN_RATIO: number = 1.5

export const HR_DIVISION_RATIO: number = 6
export const CL_DIVISION_RATIO: number = 4
