import { Thead, Tbody, Tr } from '@chakra-ui/react'
import EFeatureFlags from 'enums/featureFlags'
import useFeatureFlagValue from 'hooks/useFeatureFlagValue'
import { IMetroWithRelations } from 'interfaces/metro'
import { HR_CLEANING_FEE, HR_WIFI_FEE, STUDIO_CLEANING_FEE, STUDIO_WIFI_FEE } from '../../constants'
import { formatPrice, getPricingTableData } from '../../utils'
import { EPricingTableNormalTdContent, EPricingTableThContent } from '../DesktopPricingTable/constants'
import { PricingTh, PricingTd, PricingBoldTd, PricingNormalTd, PricingTable } from './responsivePricingTable.styles'

interface IResponsivePricingTableProps {
  metroWithPricing: IMetroWithRelations
}

const ResponsivePricingTable = (props: IResponsivePricingTableProps) => {
  const { metroWithPricing } = props
  const hrAverageRent: number = metroWithPricing?.metroPricing?.averageRent ?? 0
  const hrServiceFee: number = metroWithPricing?.metroPricing?.serviceFee ?? 0
  const { isEnabled: hasWifi = false } = useFeatureFlagValue(EFeatureFlags.WEBSITE_PRICING_TABLE_WIFI)
  const { studioAverageRent, hrTotalCost, studioTotalCost } = getPricingTableData(hrAverageRent, hrServiceFee, hasWifi)

  return (
    <PricingTable>
      <Thead>
        <Tr height={12}>
          <PricingTh></PricingTh>
          <PricingTh backgroundColor="teal.500" color="white" borderTopRadius={6}>
            {EPricingTableThContent.HOMEROOM}
          </PricingTh>
          <PricingTh color="gray.700">{EPricingTableThContent.TRADITIONAL_STUDIO}</PricingTh>
        </Tr>
      </Thead>
      <Tbody>
        <Tr height={11}>
          <PricingTd fontWeight={600}>{EPricingTableNormalTdContent.AVERAGE_RENT}</PricingTd>
          <PricingBoldTd>${formatPrice(hrAverageRent)}</PricingBoldTd>
          <PricingNormalTd>${studioAverageRent}</PricingNormalTd>
        </Tr>
        <Tr height={11}>
          <PricingTd>{EPricingTableNormalTdContent.CLEANING.split(' ')[0]}</PricingTd>
          <PricingBoldTd>${HR_CLEANING_FEE}</PricingBoldTd>
          <PricingNormalTd>${STUDIO_CLEANING_FEE}</PricingNormalTd>
        </Tr>
        {hasWifi && (
          <Tr height={11}>
            <PricingTd>{EPricingTableNormalTdContent.WIFI}</PricingTd>
            <PricingBoldTd>${HR_WIFI_FEE}</PricingBoldTd>
            <PricingNormalTd>${STUDIO_WIFI_FEE}</PricingNormalTd>
          </Tr>
        )}
        <Tr height={11}>
          <PricingTd>{EPricingTableNormalTdContent.SMART_TV}</PricingTd>
          <PricingBoldTd>Included</PricingBoldTd>
          <PricingNormalTd>-</PricingNormalTd>
        </Tr>
        <Tr height={14}>
          <PricingTd>{EPricingTableNormalTdContent.COMMON_AREA_FURNISHING}</PricingTd>
          <PricingBoldTd>Included</PricingBoldTd>
          <PricingNormalTd>-</PricingNormalTd>
        </Tr>
        <Tr height={14}>
          <PricingTd paddingX={2}>{EPricingTableNormalTdContent.KITCHEN_ESSENTIALS}</PricingTd>
          <PricingBoldTd>Included</PricingBoldTd>
          <PricingNormalTd>-</PricingNormalTd>
        </Tr>
        <Tr height={14}>
          <PricingTd>{EPricingTableNormalTdContent.SUPPORT_AND_MAINTENANCE}</PricingTd>
          <PricingBoldTd>Included</PricingBoldTd>
          <PricingNormalTd>-</PricingNormalTd>
        </Tr>
        <Tr height={11}>
          <PricingTd>{EPricingTableNormalTdContent.SERVICES_FEE}</PricingTd>
          <PricingBoldTd>${formatPrice(hrServiceFee)}</PricingBoldTd>
          <PricingNormalTd>-</PricingNormalTd>
        </Tr>
        <Tr height={11}>
          <PricingTd fontWeight={700}>{EPricingTableNormalTdContent.TOTAL_COST}</PricingTd>
          <PricingBoldTd background="teal.500" color="white" borderBottomRadius={6} padding={2}>
            ${hrTotalCost}
          </PricingBoldTd>
          <PricingNormalTd>${studioTotalCost}</PricingNormalTd>
        </Tr>
      </Tbody>
    </PricingTable>
  )
}

export default ResponsivePricingTable
