import React from 'react'
import { VStack, Image, Text } from '@chakra-ui/react'
import { ITrustCredibilityFAQsCard } from '../../constants'

export const TrustCredibilityFAQsCard = (props: ITrustCredibilityFAQsCard) => {
  const { title, description, image } = props
  return (
    <VStack
      padding={{ base: 4, md: 0, lg: 6 }}
      _hover={{ background: 'rgba(255, 255, 255, 0.4)', transition: 'all 0.3s' }}
      borderRadius="8px"
      spacing={{ base: 2, lg: 4 }}
    >
      <Image src={image} alt="FAQ" marginBottom={8} width={270} height={236.25} />
      <Text
        width="full"
        fontSize={{ base: 'md', lg: 'xl' }}
        fontWeight="normal"
        align="center"
        color="gray.800"
        lineHeight={{ base: 6, lg: 7 }}
        marginTop="0 !important"
      >
        {title}
      </Text>
      <Text
        fontSize={{ base: 'sm', lg: 'md' }}
        lineHeight={{ base: 5, lg: 6 }}
        textAlign="center"
        color="gray.800"
        maxWidth="lg"
      >
        {description}
      </Text>
    </VStack>
  )
}
