import { useEffect, useState } from 'react'
import { HStack, Text, VStack } from '@chakra-ui/react'
import { useStores } from 'hooks/useStores'
import { observer } from 'mobx-react'
import { useWatch, useFormContext } from 'react-hook-form'
import OtpInput from 'react-otp-input'
import { toast } from 'react-toastify'
import { handleError } from 'API/error'
import { getRawUSPhoneNumber } from 'utils/common'
import styles from './OTPField.module.scss'

const OTP_NUMBER_INPUTS: number = 4
const OTP_INPUT_ID: string = 'otp-input'

interface IOTPField {
  setIsDisableContinueButton: (isDisable: boolean) => void
}

const OTPField = (props: IOTPField) => {
  const { setIsDisableContinueButton } = props
  const { spinnerStore, authStore } = useStores()
  const { control } = useFormContext()
  const [currentOtp, setCurrentOtp] = useState<string>('')
  const phoneNumber: string = useWatch({ name: 'phoneNumber', control }) || ''
  const rawPhoneNumber: string = getRawUSPhoneNumber(phoneNumber)
  const [invalidOtp, setInvalidOtp] = useState<boolean>(false)

  useEffect(() => {
    if (currentOtp?.length === OTP_NUMBER_INPUTS) {
      verifyCode()
    }
  }, [currentOtp])

  async function verifyCode(): Promise<void> {
    try {
      spinnerStore.showLoading()
      const isValid: boolean = await authStore.verifyOtpToken(currentOtp, rawPhoneNumber)
      if (isValid) {
        setInvalidOtp(false)
        setIsDisableContinueButton(false)
      } else {
        setInvalidOtp(true)
        setIsDisableContinueButton(true)
      }
      spinnerStore.hideLoading()
    } catch (error) {
      handleError(
        error as Error,
        'components/pages/DetailPage/components/SideBar/components/LoginModal/components/VerificationStep/components/OTPField/index.tsx',
        'verifyCode()'
      )
      spinnerStore.hideLoading()
    }
  }

  async function resendCode(): Promise<void> {
    try {
      await authStore.requestOtpToken(rawPhoneNumber)
      toast.success('OTP has been sent to your phone number.')
    } catch (error) {
      toast.error(`The number ${phoneNumber} is not a valid phone number.`)
    }
  }

  return (
    <VStack spacing={6} alignItems="start">
      <Text maxWidth="350px" color="gray.700">
        Please enter 4 digit OTP code sent via SMS to
        <Text as="b"> {rawPhoneNumber}</Text>
      </Text>
      <HStack spacing={6} width="full" justifyContent="center">
        <OtpInput
          value={currentOtp}
          onChange={setCurrentOtp}
          numInputs={OTP_NUMBER_INPUTS}
          isInputNum
          shouldAutoFocus
          containerStyle={styles.otpInputContainer}
          inputStyle={styles.otpInput}
          focusStyle={styles.otpInputFocus}
          data-testid={OTP_INPUT_ID}
        />
      </HStack>
      <Text as="u" color="blue.500" fontWeight={400} cursor="pointer" onClick={resendCode}>
        Resend OTP
      </Text>
      <Text display={invalidOtp ? 'block' : 'none'} fontSize="sm" color="red.500">
        That code is invalid or has expired, please try again
      </Text>
    </VStack>
  )
}

export default observer(OTPField)
