import { Dispatch, SetStateAction } from 'react'
import { Button, Text } from '@chakra-ui/react'
import Icon from 'components/Icon'
import { ELoginType } from '../../../../constant'
import { THIRD_PARTY_AUTHENTICATION_ENDPOINT } from './constants'

interface ILoginOptionProps {
  loginType: ELoginType
  icon: string
  content: string
  setLoginType?: Dispatch<SetStateAction<ELoginType>>
  disabled?: boolean
}

const LoginOption = (props: ILoginOptionProps) => {
  const { loginType, icon, content, setLoginType, disabled } = props

  function handleClickLoginOption(): void {
    typeof setLoginType === 'function' && setLoginType(loginType)
    if (loginType === ELoginType.GOOGLE) {
      window.location.href = `${process.env.API_URL}${THIRD_PARTY_AUTHENTICATION_ENDPOINT}`
    }
  }

  return (
    <Button
      onClick={handleClickLoginOption}
      isFullWidth
      borderRadius="6px"
      border="1px solid"
      borderColor="gray.300"
      fontWeight={500}
      background="none"
      justifyContent="flex-start"
      _hover={{ background: 'none' }}
      disabled={disabled}
    >
      <Icon iconName={icon} size={20} />
      <Text marginLeft={2} color="gray.700" lineHeight={6}>
        {content}
      </Text>
    </Button>
  )
}

export default LoginOption
