import React, { ReactNode, useEffect } from 'react'
import { LocalStorageKeyEnum } from 'enums/common'
import EFeatureFlags from 'enums/featureFlags'
import useFeatureFlagValue from 'hooks/useFeatureFlagValue'
import { useStores } from 'hooks/useStores'
import { observer } from 'mobx-react'
import Head from 'next/head'
import { handleError } from 'API/error'
import Footer from 'components/Footer'
import BenefitsSection from 'components/Footer/components/BenefitsSection'
import PricingTableSection from 'components/Footer/components/PricingTableSection'
import RenterFAQsSection from 'components/Footer/components/RenterFAQsSection'
import TrustCredibilityFAQsSection from 'components/Footer/components/TrustCredibilityFAQsSection'
import Header from 'components/Header'
import { IMetro, IMetroWithRelations, IPricingTableSettings } from 'interfaces/metro'

interface IMainLayoutProps {
  title?: string
  children?: ReactNode
  haveFAQs?: boolean
  isEntireHouse?: boolean
  metroList?: IMetro[]
  hasPricingTable?: boolean
  metroWithPricing?: IMetroWithRelations
  pricingSettings?: IPricingTableSettings
  haveTrustCredibilitySection?: boolean
}

const MainLayout = (props: IMainLayoutProps) => {
  const {
    title,
    children,
    haveFAQs = true,
    isEntireHouse,
    metroList = [],
    hasPricingTable,
    metroWithPricing,
    pricingSettings,
    haveTrustCredibilitySection = true
  } = props
  const { metroStore, authStore } = useStores()
  const { metroList: metroListStore } = metroStore
  const hasMetro: boolean = !!metroWithPricing?.name
  const { isEnabled: isEnablePricingTable } = useFeatureFlagValue(EFeatureFlags.PRICING_TABLE)
  const isShowPricingTableSection: boolean | undefined = hasPricingTable && isEnablePricingTable && hasMetro

  function getReferrerAndSetToLocalStorage(): void {
    if (!document || !window) {
      return
    }
    const referrer: string = document?.referrer ?? ''
    const hostname: string = window?.location?.hostname ?? ''
    //*INFO: if document referrer is not in the same domain, then store the referrer in sessionStorage
    if (referrer && referrer?.indexOf(hostname) === -1) {
      localStorage.setItem(LocalStorageKeyEnum.REFERRER, referrer)
    }
  }

  async function getCurrentUser(): Promise<void> {
    try {
      await authStore.getWebsiteAccessToken()
    } catch (error) {
      handleError(error as Error, 'components/Layout/MainLayout', 'getCurrentUser')
    }
  }

  async function fetchMetroList(): Promise<void> {
    try {
      metroStore.fetchMetroList({
        where: { isActive: { neq: false } }
      })
    } catch (error) {
      handleError(error as Error, 'components/Layout/MainLayout', 'fetchMetroList')
    }
  }

  useEffect(() => {
    getCurrentUser()
    getReferrerAndSetToLocalStorage()
    if (metroList?.length === 0) {
      fetchMetroList()
    } else {
      metroStore.setMetroList(metroList)
    }
  }, [])

  // *TODO: remove the Amplitude service and will replace it with Google Analytics
  // useEffect(() => {
  //   if (isReady && window && AMPLITUDE_API_KEY && window?.localStorage) {
  //     initAmplitude()
  //     const lastViewedPropertyId: string | undefined =
  //       localStorage.getItem(LocalStorageKeyEnum.LAST_VIEWED_PROPERTY_ID) || undefined
  //     const amplitudeInstance: AmplitudeClient = amplitude.getInstance()
  //     const pageUrl: string = window?.location?.href ?? ''
  //     const pageName: string[] = String(title).split(' | ')
  //     if (pageName.length > 1 && pageName[1] === 'House Detail') {
  //       amplitudeInstance.logEvent(ActionEnum.DETAIL_PAGE_LOADED, { pageUrl, lastViewedPropertyId })
  //     }
  //     const pageAction: string = snakeCase((pageName?.length > 1 ? pageName[1] : title) + ' Page Loaded')
  //     amplitudeInstance.logEvent(pageAction, { pageUrl, lastViewedPropertyId })
  //   }
  // }, [isReady, AMPLITUDE_API_KEY, title])

  return (
    <>
      <Head>
        <title>{title || 'HomeRoom'}</title>
        <link rel="icon" href="/favicon_black.png" />
      </Head>
      <Header metroList={metroListStore} />
      <main>{children}</main>
      {haveFAQs && (
        <>
          {haveTrustCredibilitySection && <TrustCredibilityFAQsSection />}
          {isShowPricingTableSection && (
            <>
              <PricingTableSection metroWithPricing={metroWithPricing} pricingSettings={pricingSettings} />
              <BenefitsSection metroWithPricing={metroWithPricing} />
            </>
          )}
          {!isEntireHouse && <RenterFAQsSection />}
        </>
      )}
      <Footer.Desktop metroList={metroListStore} />
      <Footer.Mobile metroList={metroListStore} />
    </>
  )
}

export default observer(MainLayout)
