import { FieldValues, UseFormSetValue } from 'react-hook-form'

export interface ILoginModalProps {
  bodyText?: string
  inputPlaceholder?: string
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  previousPhoneNumber?: string
  setApplicationValue?: UseFormSetValue<FieldValues>
  handleAfterAuth?: () => void
}

export interface ILoginForm {
  phoneNumber?: string
  email?: string
  password?: string
}

export interface ISignUpForm {
  phoneNumber: string
  email: string
  password: string
  firstName: string
  lastName: string
}

export enum ELoginType {
  PHONE_NUMBER = 'phoneNumber',
  EMAIL = 'email',
  FACEBOOK = 'facebook',
  GOOGLE = 'google'
}

export enum ECurrentStep {
  VERIFICATION = 'verification',
  SIGN_UP = 'signUp',
  PERSONAL_INFORMATION = 'personalInformation',
  FORGOT_PASSWORD = 'forgotPassword',
  RESET_PASSWORD = 'resetPassword',
  COMPLETE = 'complete',
  EMAIL_VERIFICATION = 'emailVerification'
}
