import { useEffect } from 'react'
import { Menu, MenuButton, MenuList, Divider, useDisclosure, Link } from '@chakra-ui/react'
import { useStores } from 'hooks/useStores'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'
import { backgroundPrimary } from 'theme/globalStyles'
import { ETokenKey, PLATFORM } from 'API/constants'
import { handleError } from 'API/error'
import LoginModal from 'components/pages/DetailPage/components/SideBar/components/LoginModal'
import { INavLinkItem } from 'interfaces/navigation'
import routes from 'routes'
import { getValidArray } from 'utils/common'
import { profileActionItems } from '../Navbar/constants'
import UserAvatar from '../UserAvatar'
import { MenuItem, MenuItemText } from './userProfile.styles'

const UserProfile = () => {
  const { authStore } = useStores()
  const router = useRouter()
  const { route } = router
  const { user, isLogin } = authStore
  const { isOpen: isOpenLoginModal, onClose: onCloseLoginModal, onOpen: onOpenLoginModal } = useDisclosure()
  const isEmailVerified: boolean = router.query?.isEmailVerified ? true : false
  const token: string = `${router.query?.token ?? ''}`

  function handleLogout(): void {
    if (router.pathname.includes(routes.myProfile.value)) {
      router.push(routes.home.value)
    }
    authStore.clearAccessToken(PLATFORM.WEBSITE)
    toast.success('Logged out successfully.')
  }

  function handleOpenLoginModal(): void {
    onOpenLoginModal()
  }

  function setAccessToken(): void {
    localStorage.setItem(ETokenKey.WEBSITE_ACCESS_TOKEN, token)
  }

  async function handleAuthAfterEmailVerification(): Promise<void> {
    try {
      setAccessToken()
      authStore.setLogin(true)
      await authStore.getMyUser(PLATFORM.WEBSITE)
    } catch (error) {
      const errorMessage: string = get(error, 'response.data.error.message', '') || JSON.stringify(error)
      handleError(error as Error, 'components/Header/components/UserProfile', 'handleAuthAfterEmailVerification')
      throw new Error(errorMessage)
    } finally {
      if (route.includes('my-profile')) {
        router.push(routes.myProfile.value)
        toast.success('Update email successfully!', {
          toastId: 'email-verified'
        })
      } else {
        router.push(routes.home.value)
        toast.success('Your account is activated successfully!', {
          toastId: 'email-verified'
        })
      }
      authStore.updateUserProfile({ isEmailVerified: true }, PLATFORM.WEBSITE)
    }
  }

  useEffect(() => {
    if (isEmailVerified && token) {
      handleAuthAfterEmailVerification()
    }
  }, [isEmailVerified, token])

  return (
    <Menu autoSelect={false} computePositionOnMount>
      <MenuButton
        transition="all 0.3s"
        _expanded={{ background: 'teal.800', color: 'teal.50' }}
        _focus={{ boxShadow: 'none' }}
        _active={{ background: 'none' }}
        marginLeft={6}
        position={{ base: 'absolute', lg: 'static' }}
        //* INFO: depends on the width of hamburger menu and spacing between hamburger menu and user profile
        right={16}
      >
        <UserAvatar avatarUrl={user?.avatarUrl} name={isLogin ? user?.name : undefined} />
      </MenuButton>
      {isLogin ? (
        <MenuList minWidth="150px" background={backgroundPrimary} borderRadius="6px" borderWidth={0} paddingY="6px">
          {getValidArray(profileActionItems).map((item: INavLinkItem, index: number) => {
            return (
              <MenuItem key={index}>
                <Link
                  href={item.link}
                  width="full"
                  _focus={{
                    boxShadow: 'none'
                  }}
                >
                  <MenuItemText>{item?.text}</MenuItemText>
                </Link>
              </MenuItem>
            )
          })}
          <Divider color="teal.50" />
          <MenuItem>
            <MenuItemText onClick={handleLogout}>Log out</MenuItemText>
          </MenuItem>
        </MenuList>
      ) : (
        <MenuList minWidth="150px" background={backgroundPrimary} borderRadius="6px" borderWidth={0} paddingY="6px">
          <MenuItem>
            <MenuItemText onClick={handleOpenLoginModal}>Log in</MenuItemText>
          </MenuItem>
        </MenuList>
      )}
      <LoginModal isOpen={isOpenLoginModal} onOpen={onOpenLoginModal} onClose={onCloseLoginModal} />
    </Menu>
  )
}

export default observer(UserProfile)
