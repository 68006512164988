import { ModalBody, ModalContent, ModalHeader, chakra } from '@chakra-ui/react'

export const SignUpContent = chakra(ModalContent, {
  baseStyle: {
    height: { base: '100%', md: 'unset' }
  }
})

export const SignUpHeader = chakra(ModalHeader, {
  baseStyle: {
    fontSize: 'lg',
    color: 'gray.800',
    fontWeight: 500,
    lineHeight: 7,
    borderBottom: '1px',
    borderColor: 'gray.200'
  }
})

export const SignUpBody = chakra(ModalBody, {
  baseStyle: {
    fontWeight: 500,
    paddingY: 6,
    paddingX: { base: 4, md: 6 },
    display: { base: 'flex', md: 'block' },
    flex: { base: 1 },
    flexDirection: 'column',
    overflowY: 'auto'
  }
})
