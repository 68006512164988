export enum EPricingTableThContent {
  HOMEROOM = 'HOMEROOM',
  CRAIGSLIST_ROOM = 'Craigslist Room',
  TRADITIONAL_STUDIO = 'Traditional Studio'
}

export enum EPricingTableNormalTdContent {
  AVERAGE_RENT = 'Average Rent',
  CLEANING = 'Cleaning & yard care',
  WIFI = 'Wifi',
  SMART_TV = 'Smart TV',
  COMMON_AREA_FURNISHING = 'Common area furnishing',
  KITCHEN_ESSENTIALS = 'Kitchen essentials',
  SUPPORT_AND_MAINTENANCE = 'Support and maintenance**',
  SERVICES_FEE = 'Services fee*',
  TOTAL_COST = 'Total cost'
}
