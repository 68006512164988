import { Dispatch, SetStateAction, useState } from 'react'
import { Button, Checkbox, HStack, Link, ModalCloseButton, ModalFooter, Text, VStack } from '@chakra-ui/react'
import { EBreakPoint } from 'enums/theme'
import { EAccountType, ERole } from 'enums/user'
import useBreakPoint from 'hooks/useBreakPoint'
import { Controller, SubmitHandler, useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'
import { CommonError } from 'types'
import { signup, verifyEmail } from 'API/authenticate'
import FormInput from 'components/FormInput'
import Icon from 'components/Icon'
import PasswordField from 'components/PasswordField'
import { IUser, IUserWithPassword } from 'interfaces/user'
import { getFullName, getRawUSPhoneNumber } from 'utils/common'
import { ECurrentStep, ELoginType, ILoginForm, ISignUpForm } from '../../constant'
import CustomFormPhoneInput from '../CustomFormPhoneInput'
import { SignUpBody, SignUpContent, SignUpHeader } from './signUpStep.styles'
import { validatePassword } from './utils'
import styles from './styles.module.scss'

interface ISignUpStepProps {
  loginType: ELoginType
  setCurrentStep: Dispatch<SetStateAction<ECurrentStep>>
}

const SignUpStep = (props: ISignUpStepProps) => {
  const { loginType, setCurrentStep } = props
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useFormContext()
  const [isAgree, setIsAgree] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const isNameInvalid: boolean = errors?.firstName || errors?.lastName
  const isMobile: boolean = useBreakPoint(EBreakPoint.BASE, EBreakPoint.MD)
  const isTablet: boolean = useBreakPoint(EBreakPoint.BASE, EBreakPoint.LG)

  async function onSubmit(formData: ISignUpForm): Promise<void> {
    try {
      setIsLoading(true)
      const fullName: string = getFullName(formData?.firstName, formData?.lastName)

      const userData: IUserWithPassword = {
        name: fullName,
        firstName: formData?.firstName,
        lastName: formData?.lastName,
        email: formData?.email ?? '',
        phoneNumber: getRawUSPhoneNumber(formData?.phoneNumber),
        role: ERole.USER,
        accountType: EAccountType.APPLICANT,
        password: formData?.password ?? ''
      }
      const newUser: IUser = await signup(userData)
      toast.success('Signup Successfully')
      if (loginType === ELoginType.EMAIL || ELoginType.PHONE_NUMBER) {
        await verifyEmail(newUser)
        setCurrentStep(ECurrentStep.EMAIL_VERIFICATION)
        return
      }
    } catch (error) {
      let errorMessage: string = 'Signup failed.'
      if ((error as CommonError)?.message) {
        errorMessage += ` ${(error as CommonError)?.message}`
      }
      toast.error(errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  function handleSignup(): void {
    handleSubmit(onSubmit as SubmitHandler<ILoginForm>)()
  }

  return (
    <SignUpContent>
      <SignUpHeader>
        <Text>Login to HomeRoom</Text>
        <ModalCloseButton />
      </SignUpHeader>
      <SignUpBody>
        <Text marginBottom={2} color="gray.700">
          Hello newcomer&nbsp;
          <Icon iconName="hello-newcommer.svg" size={16} />
        </Text>
        <Text color="gray.700">Please enter the information to finish signing up.</Text>
        <VStack
          spacing={6}
          marginBottom={0}
          marginX="auto"
          width="full"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <HStack spacing={4} width="full" marginTop={6} marginBottom={isNameInvalid ? 6 : 0}>
            <FormInput
              height={isTablet ? '48px' : '40px'}
              width="calc((100% - 24px) / 2)"
              className={styles.inputContainer}
              name="firstName"
              label="Your Name"
              placeholder="First Name"
              autoComplete="off"
            />
            <FormInput
              height={isTablet ? '48px' : '40px'}
              width="calc((100% - 24px) / 2)"
              className={styles.inputContainer}
              name="lastName"
              label="Last Name"
              placeholder="Last Name"
              hideLabel
              autoComplete="off"
            />
          </HStack>
          {loginType === ELoginType.PHONE_NUMBER ? (
            <FormInput
              height={isTablet ? '48px' : '40px'}
              name="email"
              label="Your Email Address"
              placeholder="Enter Your Email"
              type="email"
              autoComplete="off"
            />
          ) : (
            <FormInput name="phoneNumber" label="Your Phone Number">
              <CustomFormPhoneInput />
            </FormInput>
          )}
          <PasswordField height={isTablet ? '48px' : '40px'} hasInfoIcon validate={validatePassword} />
          <FormInput name="confirm">
            <Controller
              name="confirm"
              control={control}
              rules={{ required: 'You must meet the requirement to continue.' }}
              render={({ field: { onChange } }) => (
                <Checkbox
                  className={styles.confirmCheckbox}
                  colorScheme="teal"
                  isChecked={isAgree}
                  onChange={(event) => {
                    setIsAgree(event.target.checked)
                    onChange(event.target.checked)
                  }}
                >
                  <Text lineHeight={5} color="gray.700" fontSize="sm" fontWeight={400}>
                    By selecting Complete, I agree to HomeRoom’s&nbsp;
                    <Link href="#">
                      <Text as="b" _hover={{ textDecoration: 'underline' }}>
                        Term of service&nbsp;
                      </Text>
                    </Link>
                    and the&nbsp;
                    <Link href="#">
                      <Text as="b" _hover={{ textDecoration: 'underline' }}>
                        Privacy policy.
                      </Text>
                    </Link>
                  </Text>
                </Checkbox>
              )}
            />
          </FormInput>
        </VStack>
      </SignUpBody>
      {isMobile ? (
        <VStack width="full" bottom={4} paddingX={4} marginBottom={4}>
          <Button colorScheme="teal" lineHeight={6} disabled={!isAgree} onClick={handleSignup} width="full" height={12}>
            Complete
          </Button>
          <Button
            colorScheme="teal"
            variant="link"
            lineHeight={6}
            fontWeight={500}
            paddingX="0"
            onClick={() => setCurrentStep(ECurrentStep.PERSONAL_INFORMATION)}
            width="full"
            height={12}
          >
            Back to options
          </Button>
        </VStack>
      ) : (
        <ModalFooter
          paddingTop={4}
          paddingBottom={4}
          borderTop="1px"
          borderColor="gray.200"
          justifyContent="space-between"
        >
          <Button
            colorScheme="teal"
            variant="link"
            lineHeight={6}
            fontWeight={500}
            paddingX="0"
            _hover={{ background: 'none' }}
            _focus={{ border: 'none' }}
            onClick={() => setCurrentStep(ECurrentStep.PERSONAL_INFORMATION)}
          >
            Back to options
          </Button>
          <Button colorScheme="teal" lineHeight={6} disabled={!isAgree} onClick={handleSignup} isLoading={isLoading}>
            Complete
          </Button>
        </ModalFooter>
      )}
    </SignUpContent>
  )
}

export default SignUpStep
