import { chakra, Td, Th, Tr } from '@chakra-ui/react'

export const PricingTableNormalTd = chakra(Td, {
  baseStyle: () => ({
    fontFamily: 'Inter',
    fontWeight: { base: 500, md: 400 },
    fontSize: 'md',
    lineHeight: '6',
    color: 'gray.700',
    textAlign: 'center',
    width: { base: '115px', md: '286px' },
    height: { base: '12', md: '60px' },
    padding: 0
  })
})

export const PricingTableHighlightTd = chakra(Td, {
  baseStyle: () => ({
    backgroundColor: 'rgba(230, 255, 250, 0.3)',
    fontWeight: 600,
    fontSize: 'md',
    lineHeight: '6',
    color: 'gray.700',
    textAlign: 'center',
    width: { base: '115px', md: '286px' },
    height: { base: '12', md: '60px' },
    letterSpacing: 'unset'
  })
})

export const PricingTableTr = chakra(Tr, {
  baseStyle: () => ({
    height: { base: '12', md: '60px' },
    letterSpacing: 'unset'
  })
})

export const PricingTableTh = chakra(Th, {
  baseStyle: () => ({
    fontWeight: 600,
    fontSize: { base: 'md', md: '20px' },
    lineHeight: { base: '6', lg: '7' },
    textTransform: 'capitalize',
    color: 'gray.700',
    textAlign: 'center',
    width: { base: '115px !important', md: '286px' },
    paddingX: { base: '7px', md: 0 },
    height: { base: '12', md: '60px' },
    letterSpacing: 'unset'
  })
})
