export const benefitList: string[] = [
  'Furnished common areas',
  'Kitchen essentials',
  'Smart TV',
  'Utilities split evenly',
  'Easy payment through the app',
  'Improve your credit score with on-time rent payments'
]

export const termAndConditionLink: string = 'https://livehomeroom.com/happy-homes'
