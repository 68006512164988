enum EFeatureFlags {
  WEBSITE_LOGIN = 'website-login',
  WEBSITE_LISTING_PAGE_ENTIRE_HOUSE = 'website-listing-page-entire-house',
  WEBSITE_APPLICATION_FORM_V2 = 'application-form-v2',
  WEBSITE_APPLICATION_FORM_OTP_VERIFY = 'application-form-otp-verify',
  WEBSITE_APPLICATION_FORM_CRS_CHECK = 'application-form-crs-check',
  WEBSITE_APPLICATION_FORM_SIGN_AGREEMENT = 'application-form-sign-agreement',
  WEBSITE_APPLICATION_FORM_PAYMENT = 'application-form-payment',
  WEBSITE_APPLICATION_FORM_ROOMMATE_SMS = 'application-form-roommate-sms',
  WEBSITE_APPLICATION_FORM_CHOOSE_UNIT_ON_SIDEBAR_47 = 'application-form-choose-unit-in-sidebar-47',
  WEBSITE_PRICING_TABLE_WIFI = 'website-pricing-table-wifi-50',
  WEBSITE_CHATBOX = 'website-chatbox-50',
  OWNER_PORTAL_LOGIN = 'owner-portal-login',
  CMS = 'cms',
  OWNER_PORTAL = 'owner-portal',
  OWNER_PORTAL_FINANCIAL = 'owner-portal-financial',
  OWNER_PORTAL_LEASE = 'owner-portal-lease',
  OWNER_PORTAL_REPAIR = 'owner-portal-repair',
  CMS_PAYMENT_LIST = 'cms-payment-list',
  CMS_UNIT_MAIN_PHOTO = 'cms-unit-main-photo',
  PRICING_TABLE = 'pricing-table'
}

export default EFeatureFlags
