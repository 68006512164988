import { IAmenitiesPackage } from 'interfaces/amenitiesPackage'
import { IHouseDefaultValue } from 'interfaces/houseDefaultValue'
import { IRoomDefaultValue } from 'interfaces/roomDefaultValue'
import { IProperty } from './../../../interfaces/property/index'

export const defaultAmenities: IAmenitiesPackage = {
  title: 'Additional Monthly amenities and Service Fee ($140/mo) includes:',
  utilityFee: 'Flat rate utility fee',
  maintenance: '24/7 Emergency Maintenance',
  internet: 'High-speed, unlimited WiFi',
  laundry: 'In-house laundry',
  maidService: 'Maid Service',
  smartTV: '50" Smart TV',
  lawnCare: 'Lawn Care',
  events: 'Exclusive Community and Events',
  homeRoomApp: 'Custom-built HomeRoom App',
  creditScore: 'Increase your credit score with each rent payment'
}

export const helperSMSLink: string = 'sms:+18162031186'

export interface IDetailPageProps {
  houseDetail: IProperty
  defaultHouse: IHouseDefaultValue
  defaultRoom: IRoomDefaultValue
  amenitiesPackage: IAmenitiesPackage
}

export interface IAmenitiesPageProps {
  amenitiesPackage: IAmenitiesPackage
  houseDetail: IProperty
}

export const roommateDescriptionList: string[] = [
  `Hi! We're looking for someone who enjoys a pleasant and laid back roomate dynamic. We're a group of people with different professions and hobbies, would love to find someone interesting to add to the mix!  A + if you like to go hiking or walks, enjoy sports or athletics or grab dinner.`,
  `We’re a mix of later-stage students and professionals. We were randomly paired together as roommates and it's worked out great. Looking maintain the chill and respectful dynamic we have established. Let's meet if that sounds like you!`,
  `Eclectic group of nice, mature people here! We're all working professionals but as soon as we come home we like to chill, relax and chat about our days. Looking for someone who appreciates a moderately social but laid back vibe.`,
  `We are a group of individuals in various age groups looking for someone who is clean, responsible, friendly and chill.
  Looking forward to meeting you!`,
  `We’re a group of professionals and creative folks. Very chill, respectful and positive vibes. Cool with having friends over time to time. Prefer a clean person who helps out when needed.`
]

export const TEST_PHONE_NUMBER: string = '+15555555555'

export const AMENITIES_ICON_MAP: Record<string, string> = {
  utilityFee: 'fee.svg',
  maintenance: 'maintenance.svg',
  internet: 'wifi.svg',
  laundry: 'washing_machine.svg',
  maidService: 'clean.svg',
  smartTV: 'TV.svg',
  lawnCare: 'lawn.svg',
  events: 'event.svg',
  homeRoomApp: 'app.svg',
  creditScore: 'payment.svg'
}

export const MATTERPORT_DOMAIN: string = 'matterport'
