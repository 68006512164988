import { useState } from 'react'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { Stack, Box as CkBox, VStack, HStack, Collapse } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { getTabletFooterLinks } from 'components/Footer/utils'
import Icon from 'components/Icon'
import NextLink from 'components/NextLink'
import { IMetro } from 'interfaces/metro'
import { IFooterNavLink, IFooterNavLinkItem } from 'interfaces/navigation'
import { getValidArray } from 'utils/common'
import { tabletFooterLinks, ISocialLink, socialLinks } from '../../constants'
import {
  Container,
  Box,
  NavigationSection,
  SocialButton,
  FooterColumn,
  CommonDivider,
  NavigationLink,
  Text,
  DropdownButton,
  TextWrapper
} from '../../footer.styles'

interface IFooterMobileProps {
  metroList?: IMetro[]
}

const FooterMobile = (props: IFooterMobileProps) => {
  const [openIndex, setOpenIndex] = useState<number>(-1)
  const { metroList } = props

  function toggleCollapse(index: number) {
    if (openIndex === index) {
      setOpenIndex(-1)
    } else {
      setOpenIndex(index)
    }
  }
  return (
    <CkBox as="footer" background="orange.50" display={{ base: 'block', md: 'none' }}>
      <Container>
        <NavigationSection>
          <NavigationLink columns={1} spacing={8}>
            {getValidArray([...tabletFooterLinks, ...getTabletFooterLinks(metroList)]).map(
              (footer: IFooterNavLink, indexFooter: number) => (
                <FooterColumn key={indexFooter}>
                  <DropdownButton
                    _focus={{ boxShadow: 'none' }}
                    fontSize={20}
                    fontWeight={700}
                    lineHeight={7}
                    rightIcon={openIndex === indexFooter ? <ChevronUpIcon /> : <ChevronDownIcon />}
                    onClick={() => toggleCollapse(indexFooter)}
                  >
                    {footer.title}
                  </DropdownButton>
                  <Collapse in={openIndex === indexFooter}>
                    <Stack as="ul" listStyleType="none" spacing={4} marginTop={6}>
                      {getValidArray(footer.links).map((link: IFooterNavLinkItem, index: number) => (
                        <TextWrapper as="li" key={index}>
                          <NextLink href={link.link}>
                            <Text>{link.text}</Text>
                          </NextLink>
                        </TextWrapper>
                      ))}
                    </Stack>
                  </Collapse>
                </FooterColumn>
              )
            )}
          </NavigationLink>
          <VStack spacing={8} flex={0} marginTop={10}>
            <VStack spacing={4} alignItems="flex-start">
              <a href="mailto:info@livehomeroom.com">
                <HStack spacing={2} flexWrap="wrap">
                  <Text isTitle>General:</Text>
                  <Text>info@livehomeroom.com</Text>
                </HStack>
              </a>
              <a href="mailto:invest@livehomeroom.com">
                <HStack spacing={2} flexWrap="wrap">
                  <Text isTitle>Investing:</Text>
                  <Text>invest@livehomeroom.com</Text>
                </HStack>
              </a>
              <HStack spacing={2} alignItems="flex-start" flexWrap="wrap">
                <Text>HomeRoom, Prairie Village, KS 66208, United States</Text>
              </HStack>
            </VStack>
            <HStack spacing={4} marginTop={8} alignSelf="flex-start">
              {socialLinks.map((social: ISocialLink) => (
                <SocialButton key={social.label} href={social.href} target="_blank">
                  <Icon size={32} iconName={social.icon} />
                </SocialButton>
              ))}
            </HStack>
          </VStack>
        </NavigationSection>
        <CommonDivider />
        <Box>© Copyright {dayjs().year()} HomeRoom, Inc.</Box>
      </Container>
    </CkBox>
  )
}

export default FooterMobile
