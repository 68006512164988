import { Dispatch, SetStateAction } from 'react'
import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  VStack
} from '@chakra-ui/react'
import get from 'lodash/get'
import { useFormContext } from 'react-hook-form'
import { EMAIL_PATTERN } from 'constants/common'
import { ELoginType } from '../../constant'
import CustomFormPhoneInput from '../CustomFormPhoneInput'
import LoginOption from './components/LoginOption'

interface IPersonalInformationStepProps {
  loginType: ELoginType
  setLoginType: Dispatch<SetStateAction<ELoginType>>
}

const PersonalInformationStep = (props: IPersonalInformationStepProps) => {
  const { loginType, setLoginType } = props
  const {
    register,
    formState: { errors, isSubmitting }
  } = useFormContext()
  const errorMessage: string = get(errors, `${loginType}.message`, '')
  const isLoginWithPhoneNumber: boolean = loginType === ELoginType.PHONE_NUMBER

  return (
    <ModalContent>
      <ModalHeader
        fontSize="lg"
        color="gray.800"
        fontWeight={500}
        lineHeight={7}
        borderBottom="1px"
        borderColor="gray.200"
      >
        <Text>Login to HomeRoom</Text>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody fontWeight={500} paddingY={6}>
        <FormControl isInvalid={!!errorMessage}>
          <Text fontWeight={600} fontSize="lg" height={7} lineHeight={7}>
            Welcome to HomeRoom!
          </Text>
          <Text marginTop={6} marginBottom={2} color="gray.700">
            Get started with your {isLoginWithPhoneNumber ? 'phone number' : 'e-mail'}
          </Text>
          {isLoginWithPhoneNumber ? (
            <CustomFormPhoneInput isAutoFocus />
          ) : (
            <Input
              autoFocus
              placeholder="Enter your E-mail"
              height={{ base: 12, lg: 10 }}
              {...register('email', {
                pattern: {
                  value: EMAIL_PATTERN,
                  message: 'Invalid E-mail'
                },
                required: 'E-mail is required'
              })}
            />
          )}
          <FormErrorMessage marginTop="5px">{errorMessage}</FormErrorMessage>
          <Button isFullWidth colorScheme="teal" marginTop={6} isLoading={isSubmitting} type="submit" lineHeight={6}>
            Continue
          </Button>
        </FormControl>
      </ModalBody>
      <Flex align="center" marginBottom={6}>
        <Divider borderColor="gray.500" />
        <Text paddingX={6} color="gray.500" fontWeight={400}>
          Or
        </Text>
        <Divider borderColor="gray.500" />
      </Flex>
      <ModalFooter flexDirection="column" paddingTop={0} paddingBottom={6}>
        <VStack spacing={4} width="full">
          <LoginOption
            loginType={ELoginType.FACEBOOK}
            icon="circleFacebook.svg"
            content="Continue with Facebook"
            disabled
          />
          <LoginOption loginType={ELoginType.GOOGLE} icon="google.svg" content="Continue with Google" />
          {isLoginWithPhoneNumber ? (
            <LoginOption
              loginType={ELoginType.EMAIL}
              icon="email.svg"
              content="Continue with E-mail"
              setLoginType={setLoginType}
            />
          ) : (
            <LoginOption
              loginType={ELoginType.PHONE_NUMBER}
              icon="phone.svg"
              content="Continue with Phone number"
              setLoginType={setLoginType}
            />
          )}
        </VStack>
      </ModalFooter>
    </ModalContent>
  )
}

export default PersonalInformationStep
