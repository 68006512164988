import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  VStack
} from '@chakra-ui/react'
import { EBreakPoint } from 'enums/theme'
import useBreakPoint from 'hooks/useBreakPoint'
import { useStores } from 'hooks/useStores'
import { observer } from 'mobx-react'
import { useFormContext, useWatch } from 'react-hook-form'
import { toast } from 'react-toastify'
import { verifyEmail } from 'API/authenticate'
import PasswordField from 'components/PasswordField'
import { IWebsiteLoginResponse } from 'interfaces/authenticate'
import { getRawUSPhoneNumber } from 'utils/common'
import { ILoginForm, ELoginType, ECurrentStep } from '../../constant'
import OTPField from './components/OTPField'

interface IVerificationStepProps {
  loginType: ELoginType
  setCurrentStep: Dispatch<SetStateAction<ECurrentStep>>
  onClose: () => void
}

const VerificationStep = (props: IVerificationStepProps) => {
  const { authStore } = useStores()
  const { handleSubmit, control } = useFormContext()
  const { loginType, setCurrentStep, onClose } = props
  const isMobile: boolean = useBreakPoint(EBreakPoint.BASE, EBreakPoint.MD)
  const isTablet: boolean = useBreakPoint(EBreakPoint.BASE, EBreakPoint.LG)
  const [isDisableContinueButton, setIsDisableContinueButton] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const userPassword: string = useWatch({ name: 'password', control }) || ''

  async function onSubmit(data: ILoginForm): Promise<void> {
    const { phoneNumber = '', email = '', password = '' } = data
    if (loginType === ELoginType.PHONE_NUMBER) {
      setIsLoading(true)
      const rawPhoneNumber: string = getRawUSPhoneNumber(phoneNumber)
      const websiteLoginResponse: IWebsiteLoginResponse = await authStore.loginWithPhoneNumber(rawPhoneNumber)
      if (websiteLoginResponse?.userId) {
        if (!websiteLoginResponse?.isEmailVerified) {
          verifyEmail({
            id: websiteLoginResponse?.userId
          })
          setCurrentStep(ECurrentStep.EMAIL_VERIFICATION)
          return
        }
        await authStore.setAccessTokenWithPhoneNumber(rawPhoneNumber)
        setIsLoading(false)
        toast.success('Login successfully!')
        onClose()
      } else {
        setIsLoading(false)
        setCurrentStep(ECurrentStep.SIGN_UP)
      }
    } else if (loginType === ELoginType.EMAIL) {
      setIsLoading(true)
      const websiteLoginResponse: IWebsiteLoginResponse = await authStore.setAccessTokenWithEmail(email, password)
      if (websiteLoginResponse?.userId && websiteLoginResponse?.token) {
        if (!websiteLoginResponse?.isEmailVerified) {
          verifyEmail({
            id: websiteLoginResponse?.userId
          })
          setCurrentStep(ECurrentStep.EMAIL_VERIFICATION)
          return
        }
        setIsLoading(false)
        toast.success('Login successfully!')
        onClose()
      } else {
        setIsLoading(false)
        toast.error('Wrong password, please try again!')
      }
    }
  }

  function handleLogin(): void {
    handleSubmit(onSubmit)()
  }

  useEffect(() => {
    if (loginType === ELoginType.EMAIL && userPassword) {
      setIsDisableContinueButton(false)
    }
  }, [loginType, userPassword])

  return (
    <ModalContent>
      <ModalHeader
        fontSize="lg"
        color="gray.800"
        fontWeight={500}
        lineHeight={7}
        borderBottom="1px"
        borderColor="gray.200"
      >
        <Text>Login to HomeRoom</Text>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody fontWeight={500} paddingY={6} paddingX={{ base: 4, md: 6 }}>
        {loginType === ELoginType.PHONE_NUMBER ? (
          <OTPField setIsDisableContinueButton={setIsDisableContinueButton} />
        ) : (
          <VStack alignItems="start" spacing={6}>
            <PasswordField height={isTablet ? '48px' : '40px'} autoFocus />
            <Text
              as="u"
              color="blue.500"
              fontWeight={400}
              cursor="pointer"
              onClick={() => setCurrentStep(ECurrentStep.FORGOT_PASSWORD)}
            >
              Forgot password?
            </Text>
          </VStack>
        )}
      </ModalBody>
      {isMobile ? (
        <VStack width="full" bottom={4} paddingX={4} marginBottom={4}>
          <Button colorScheme="teal" lineHeight={6} onClick={handleLogin} width="full" height={12}>
            Complete
          </Button>
          <Button
            colorScheme="teal"
            variant="link"
            lineHeight={6}
            fontWeight={500}
            paddingX="0"
            onClick={() => setCurrentStep(ECurrentStep.PERSONAL_INFORMATION)}
            width="full"
            height={12}
          >
            Back to options
          </Button>
        </VStack>
      ) : (
        <ModalFooter
          paddingTop={4}
          paddingBottom={4}
          borderTop="1px"
          borderColor="gray.200"
          justifyContent="space-between"
        >
          <Button
            colorScheme="teal"
            variant="link"
            lineHeight={6}
            fontWeight={500}
            paddingX="0"
            _hover={{ background: 'none' }}
            _focus={{ border: 'none' }}
            onClick={() => setCurrentStep(ECurrentStep.PERSONAL_INFORMATION)}
          >
            Back to options
          </Button>
          <Button
            colorScheme="teal"
            lineHeight={6}
            onClick={handleLogin}
            isDisabled={isDisableContinueButton}
            isLoading={isLoading}
          >
            Continue
          </Button>
        </ModalFooter>
      )}
    </ModalContent>
  )
}

export default observer(VerificationStep)
