import React from 'react'
import { CheckIcon, Icon } from '@chakra-ui/icons'
import { Box, chakra, Flex, HStack, Img, SimpleGrid, Text, VStack } from '@chakra-ui/react'
import { EBreakPoint } from 'enums/theme'
import useBreakPoint from 'hooks/useBreakPoint'
import { IMetroWithRelations } from 'interfaces/metro'
import { getValidArray } from 'utils/common'
import { benefitList, termAndConditionLink } from './constant'
const trialBackground: string = '/assets/images/30-day-trial.png'
const benefitsSection: string = '/assets/images/benefits-section.png'

interface IBenefitsSection {
  metroWithPricing?: IMetroWithRelations
}

const BenefitsSection = (props: IBenefitsSection) => {
  const { metroWithPricing = {} as IMetroWithRelations } = props
  const isMobile: boolean = useBreakPoint(EBreakPoint.BASE, EBreakPoint.LG)
  const serviceFee: number = metroWithPricing?.metroPricing?.serviceFee ?? 0

  function handleOpenTermAndCondition(): void {
    window.open(termAndConditionLink, '_blank')
  }

  return (
    <VStack
      backgroundColor="#F3EADA"
      paddingX={{ base: 0, lg: 150 }}
      spacing={{ base: 10, lg: 20 }}
      marginTop={{ base: 0, lg: 120 }}
      paddingBottom={{ base: 10, lg: 20 }}
    >
      <Flex
        flexDirection={{ base: 'column', lg: 'row' }}
        alignItems="center"
        backgroundColor="#FFFAF0"
        gap={8}
        borderRadius={{ base: 0, lg: '8px' }}
        marginTop={{ lg: -120 }}
        paddingY={10}
        paddingX={{ base: 4, md: 8, lg: 10 }}
        maxWidth={{ lg: 1140 }}
      >
        {!isMobile && (
          <Box>
            <Img src={`${trialBackground}`} maxWidth={400} />
          </Box>
        )}
        <VStack alignItems={{ base: 'center', lg: 'flex-start' }} spacing={{ base: 2, lg: 6 }} width="full">
          <Text
            fontSize={{ base: 'xl', lg: '4xl' }}
            lineHeight={{ base: 7, lg: '44px' }}
            fontWeight={700}
            textAlign={{ base: 'center', lg: 'left' }}
          >
            Happy Homes - <chakra.span color="teal.500">30-Day Test Run</chakra.span>
          </Text>
          {isMobile && (
            <Flex paddingY={2} justifyContent={{ base: 'center', lg: 'unset' }}>
              <Img src={`${trialBackground}`} maxWidth={250} />
            </Flex>
          )}
          <VStack spacing={{ base: 2, lg: 4 }} alignItems={{ base: 'center', lg: 'flex-start' }} width="full">
            <Text
              fontSize={{ base: 'md', lg: 'lg' }}
              lineHeight={{ base: 6, lg: 7 }}
              textAlign={{ base: 'center', lg: 'left' }}
            >
              Here at <b>HomeRoom</b>,
              {`we're confident you'll adore your new living space and housemates! However, we
              understand that every situation is unique.`}
            </Text>
            <Text
              fontSize={{ base: 'md', lg: 'lg' }}
              lineHeight={{ base: 6, lg: 7 }}
              textAlign={{ base: 'center', lg: 'left' }}
            >
              {`That's why we provide alternatives if you decide it's not a perfect fit within 30 days of your move-in.
              Relocate to another available HomeRoom home and find your perfect fit.*`}
            </Text>
          </VStack>

          <VStack spacing={0}>
            <Text
              fontSize="sm"
              lineHeight={5}
              fontWeight={500}
              color="teal.500"
              cursor="pointer"
              onClick={handleOpenTermAndCondition}
            >
              * Click here to read Terms and conditions.
            </Text>
          </VStack>
        </VStack>
      </Flex>
      <Flex
        gap={{ lg: 8 }}
        width="full"
        alignItems={{ base: 'center', lg: 'flex-start' }}
        flexDirection={{ base: 'column', lg: 'row' }}
        justifyContent={{ base: 'center', lg: 'unset' }}
        paddingX={{ base: 4, md: 8, lg: 0 }}
        maxWidth={{ lg: 1140 }}
      >
        <VStack spacing={{ base: 4, lg: 6 }} maxWidth={{ lg: 700 }} width="full">
          <VStack spacing={{ base: 0, lg: 4 }} alignItems="flex-start" width="full">
            <Text
              fontSize={{ base: 'lg', lg: '4xl' }}
              lineHeight={{ base: 7, lg: '44px' }}
              fontWeight={700}
              color="teal.500"
            >
              Numerous Advantages!
            </Text>
            <Text fontSize={{ base: 'lg', lg: '4xl' }} lineHeight={{ base: 7, lg: '44px' }} fontWeight={700}>
              All encompassed in a single ${serviceFee} monthly charge
            </Text>
            <VStack spacing={0} alignItems="flex-start" width="full" paddingTop={{ base: 4, lg: 0 }}>
              <Text fontSize={{ base: 'md', lg: 'lg' }} lineHeight={{ base: 6, lg: 7 }}>
                Our goal is to ensure an exceptional living experience for you and your housemates. The ${serviceFee}{' '}
                per month* flat rate for services includes:
              </Text>
            </VStack>
          </VStack>
          <VStack alignItems="flex-start" width="full" paddingY={{ base: 2, lg: 4 }}>
            <SimpleGrid columns={1} gap={{ base: 2, lg: 4 }}>
              {getValidArray(benefitList).map((benefit: string, index: number) => {
                if (!benefit) return <></>
                return (
                  <HStack spacing={{ base: 2, lg: 4 }} key={index} alignItems="flex-start">
                    <Icon
                      bg="teal.500"
                      width={{ base: 4, lg: 5 }}
                      h={{ base: 4, lg: 5 }}
                      borderRadius="2px"
                      marginTop="3px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      padding={{ base: 1, lg: '6px' }}
                    >
                      <CheckIcon color="white" marginTop="2px" width={2} />
                    </Icon>
                    <Text
                      fontSize={{ base: 'md', lg: 'xl' }}
                      lineHeight={{ base: 6, lg: 7 }}
                      color="gray.700"
                      textAlign="left"
                      fontWeight={{ base: 500, lg: 400 }}
                    >
                      {benefit}
                    </Text>
                  </HStack>
                )
              })}
            </SimpleGrid>
          </VStack>
          {!isMobile && (
            <VStack spacing={2} alignItems="flex-start" width="full">
              <Text fontSize="sm" lineHeight={5} color="gray.600" maxWidth={520}>
                * Base service fee covers mobile app access, plus common area furnishings and kitchen essentials —
                valued at more than $5000!
              </Text>
              <Text fontSize="sm" lineHeight={5} color="gray.600">
                ** Resident-caused damages are billed to the resident.
              </Text>
            </VStack>
          )}
        </VStack>
        <Flex
          marginTop={{ base: 4, lg: 10 }}
          justifyContent={{ base: 'center', lg: 'unset' }}
          width={{ base: 'full', lg: 'fit-content' }}
          maxWidth={{ base: 300, lg: 380 }}
        >
          <Img src={`${benefitsSection}`} />
        </Flex>
      </Flex>
    </VStack>
  )
}

export default BenefitsSection
