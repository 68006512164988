import { ReactNode } from 'react'
import Link from 'next/link'

export interface INextLinkProps {
  href?: string
  children?: ReactNode
}

const NextLink = (props: INextLinkProps) => {
  const { href, children } = props
  return (
    <Link href={href ?? '/'} passHref>
      <a target="_blank" rel="noreferrer">
        {children}
      </a>
    </Link>
  )
}

export default NextLink
